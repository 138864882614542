















































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  find: (b: string, c: string) => ApiResource.Commission,
  getStats: (c: string) => ApiResource.CommissionStats | undefined,
  listFiles: (c: string) => ApiResource.CommissionFile[],
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    FileDeleteButton: () => import(/* webpackChunkName: "c-f-rm" */ '@/components/commission/FileDeleteButton.vue'),
    FileDownloadButton: () => import(/* webpackChunkName: "c-f-dl" */ '@/components/commission/FileDownloadButton.vue'),
    FileUsage: () => import(/* webpackChunkName: "c-f-u" */ '@/components/commission/FileUsage.vue'),
    MediaCommissionFile: () => import(/* webpackChunkName: "m-c-f" */ '@/components/media/CommissionFile.vue'),
  },
  data() {
    return {
      error: null as Error|null,
    };
  },
  computed: {
    ...mapGetters({
      currentUserId: 'account/Id',
      detect: 'business/Detect',
      find: 'business/commission/Find',
      getStats: 'business/commission/Stats',
      listFiles: 'business/commission/file/List',
    }),
    COMMISSION_ID(): string {
      return this.$route.params.COMMISSION_ID;
    },
    VANITY(): string {
      return this.$route.params.VANITY;
    },
    BUSINESS(): ApiResource.Business {
      // Fallback to finding by ID automatically
      return this.detect(this.VANITY);
    },
    commission(): ApiResource.Commission {
      return this.find(this.BUSINESS.id, this.COMMISSION_ID);
    },
    files(): ApiResource.CommissionFile[] {
      return this.listFiles(this.COMMISSION_ID)
        .sort((
          a: ApiResource.CommissionFile,
          b: ApiResource.CommissionFile,
        ) => b.created_at - a.created_at);
    },
    stats(): ApiResource.CommissionStats | undefined {
      return this.getStats(this.COMMISSION_ID);
    },
  },
  watch: {
    '$route.params.COMMISSION_ID': {
      immediate: true,
      handler() {
        this.fetch();
      },
    },
  },
  methods: {
    handleError(e: Error) {
      this.error = e;
    },
    async fetch() {
      await this.$store.dispatch('business/commission/file/List', {
        BUSINESS_ID: this.BUSINESS.id,
        COMMISSION_ID: this.COMMISSION_ID,
      }).catch(this.handleError);
    },
  },
});
