var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('FileUsage',{attrs:{"commission-stats":_vm.stats,"storage-zone":_vm.commission.zone}}),_c('hr'),_c('ErrorMessage',{attrs:{"error":_vm.error}}),_c('ListComponent',{attrs:{"is-empty":_vm.files.length === 0,"is-error":!!_vm.error},scopedSlots:_vm._u([{key:"list",fn:function(){return _vm._l((_vm.files),function(file){return _c('MediaCommissionFile',{key:file.id,attrs:{"file":file,"is-owner":file.user_id === _vm.currentUserId},scopedSlots:_vm._u([{key:"file-name",fn:function(){return [_c('RouterLink',{staticClass:"font-bold",attrs:{"to":{
              name: 'dashboard.commission.file',
              params: {
                VANITY: _vm.VANITY,
                COMMISSION_ID: _vm.COMMISSION_ID,
                FILE_ID: file.id,
              }
            }},domProps:{"textContent":_vm._s(file.name)}})]},proxy:true},{key:"delete-button",fn:function(){return [_c('FileDeleteButton',{attrs:{"business-id":_vm.BUSINESS.id,"commission-id":_vm.COMMISSION_ID,"file-id":file.id},on:{"file-delete-error":_vm.handleError}})]},proxy:true},{key:"download-button",fn:function(){return [_c('FileDownloadButton',{staticClass:"is-medium is-link",attrs:{"file-data":file,"commission-id":_vm.COMMISSION_ID,"business-id":_vm.BUSINESS.id,"on-success-open":""},on:{"file-download-error":_vm.handleError}})]},proxy:true},{key:"required-item",fn:function(){return [_c('RouterLink',{staticClass:"button has-text-link is-medium",attrs:{"to":{
              name: 'dashboard.commission.item',
              params: {
                VANITY: _vm.VANITY,
                COMMISSION_ID: _vm.COMMISSION_ID,
                ITEM_ID: file.item_id,
              }
            }},domProps:{"textContent":_vm._s(_vm.$t('view-item'))}})]},proxy:true}],null,true)})})},proxy:true},{key:"error",fn:function(){return [_c('ErrorMessage',{attrs:{"error":_vm.error}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }